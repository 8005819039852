











































































import { Component, Vue } from "vue-property-decorator";
import AuthService from "../../services/AuthService";
import AlertService from "../../../common-app/service/AlertService";

@Component({})
export default class ResetPassword extends Vue {
  public firstForm: boolean = true;
  public secondForm: boolean = false;
  public isConfirm: boolean = false;
  public submitted: boolean = false;
  public emailorpass: string = "";
  public code: string = "";
  public password: string = "";
  public message: string = "";
  public userInfo: any;
  public messageText: string = "A code sent to your email. Please check and type/enter the code";
  created(){
    const dict = {
      custom: {
        phone:{
          required: "The phone is required"
        },
        code: {
          required: "The code is required"
        },
        password: {
          required: "The password is required"
        }
      }
    };
    this.$validator.localize("en", dict);
  }

  onSubmitEmail() {
    this.$validator.validate().then((valid) => {
      if (valid) {
        this.submitted = true;
        AuthService.resetPassword(this.emailorpass).then((res) => {
          if (!res.data.status) {
            this.submitted = false;
            //alert(res.data.message);
            AlertService.sendMessage(res.data);
            return this.$validator.reset();
          }
          this.messageText = res.data.message;
          this.firstForm = false;
          this.secondForm = true;
          this.submitted = false;
          this.userInfo = res.data.result || {};
        });
      }
    });
  }

  onSubmit() {
    this.$validator.validate().then((valid) => {
      if (valid) {
        if (this.userInfo.code == this.code) {
          this.submitted = true;
          AuthService.resetPasswordVerify(this.password, this.userInfo).then((res: any) => {
            if (res.data.status) {
              this.message = res.data.message;
              this.isConfirm = true;
              this.firstForm = false;
              this.secondForm = false;
              return;
            }
            this.$validator.reset();
            this.submitted = false;
            AlertService.sendMessage(res.data);
          });
        } else {
          this.submitted = false;
          // alert("code invalid");
          AlertService.sendMessage({message:"You have enter wrong password",status:false});
        }
      }
    });
  }
}
